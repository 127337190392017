// NOTE: This jQuery module is also used by our legacy desktop JS—be careful when modifying!

import $ from 'jquery'

import addBootstrapAlert      from './jquery/bootstrap/alert'
import addBootstrapButton     from './jquery/bootstrap/button'
import addBootstrapModal      from './jquery/bootstrap/modal'
import addBootstrapTransition from './jquery/bootstrap/transition'
import addBootstrapTooltip    from './jquery/bootstrap/tooltip'
import addBootstrapPopover    from './jquery/bootstrap/popover'
import addBootstrapDropdown    from './jquery/bootstrap/dropdown'

if (typeof window !== 'undefined') {
  addBootstrapAlert($)
  addBootstrapButton($)
  addBootstrapModal($)
  addBootstrapTransition($)
  addBootstrapTooltip($)
  addBootstrapPopover($)
  addBootstrapDropdown($)

  // Add CSRF token to all AJAX requests
  $.ajaxPrefilter((options, originalOptions, jqXHR) => {
    if (options.crossDomain) return

    const token = $('meta[name="csrf-token"]').attr('content')
    if (token) jqXHR.setRequestHeader('X-CSRF-Token', token)
  })
}

export default $
